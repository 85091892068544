<template>
  <div>
    <page-heading
      heading="Оценка кадрового потенциала"
    />
    <el-card>
      <div>
        <el-table
          ref="cardsTable"
          style="width: 100%"
          :data="cards"
          empty-text="Нет карт"
          v-loading="loading"
          :row-key="(row) => row.id"
        >
          <el-table-column label="ФИО Сотрудника/Руководителя" v-slot="scope" width="150">
            {{ scope.row.user.full_name }}
          </el-table-column>
          <el-table-column label="Локация" v-slot="scope" width="150">
            {{ scope.row.user.location }}
          </el-table-column>
          <el-table-column label="Подразделение" v-slot="scope" width="200">
            {{ scope.row.user.subdivision }}
          </el-table-column>
          <el-table-column label="Цех" v-slot="scope" width="150">
            {{ scope.row.user.workshop }}
          </el-table-column>
          <el-table-column label="ФИО непосредственного руководителя" v-slot="scope" width="150">
            {{ scope.row.user.directChief ? scope.row.user.directChief.full_name : '' }}
          </el-table-column>
          <el-table-column label="Год карты целей" v-slot="scope" width="150">
            <router-link-to-card-column-template
                :row="scope.row"
                :value="scope.row.year.toString()"
            />
          </el-table-column>
          <el-table-column label="Статус карты целей" v-slot="scope" width="150">
            <current-stage-column-template
              :row="scope.row"
            />
          </el-table-column>
          <el-table-column label="Итоговая оценка эффективности деятельности" v-slot="scope" width="300">
            <total-evaluate-select-column
              permission="card.total_evaluate_final_efficiency_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_FINAL_EFFICIENCY']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_FINAL_EFFICIENCY']"
              :row="scope.row"
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column label="Потенциал" v-slot="scope" width="300">
            <total-evaluate-select-column
              v-if="scope.row.info.hasOwnProperty(totalEvaluations['TOTAL_EVALUATE_POTENTIAL'])"
              permission="card.subordinate_evaluations_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_POTENTIAL']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_POTENTIAL']"
              :row="scope.row"
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column label="Риск увольнения" v-slot="scope" width="300">
            <total-evaluate-select-column
              v-if="scope.row.info.hasOwnProperty(totalEvaluations['TOTAL_EVALUATE_RISK_DISMISSAL'])"
              permission="card.subordinate_evaluations_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_RISK_DISMISSAL']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_RISK_DISMISSAL']"
              :row="scope.row"
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column label="Влияние на бизнес" v-slot="scope" width="300">
            <total-evaluate-select-column
              v-if="scope.row.info.hasOwnProperty(totalEvaluations['TOTAL_EVALUATE_IMPACT_ON_BUSINESS'])"
              permission="card.subordinate_evaluations_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_IMPACT_BUSINESS']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_IMPACT_ON_BUSINESS']"
              :row="scope.row"
            ></total-evaluate-select-column>
          </el-table-column>
        </el-table>
        <paginator
          :paginator.sync="paginator"
          use-router-query-param
          @page-changed="loadCards"
        ></paginator>
      </div>
    </el-card>
  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import CurrentStageColumnTemplate from "@/components/tableColumnTemplates/cards/StatusColumnTemplate";
import RouterLinkToCardColumnTemplate from "@/componentsCompany/smz/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import CardsComponent from "@/components/cards/CardsComponent";
import {mapGetters} from "vuex";
import TotalEvaluateEntity from "@/componentsCompany/smz/TotalEvaluateEntity";
import TotalEvaluateSelectColumn from "@/componentsCompany/smz/tableColumnTemplates/TotalEvaluateSelectColumn";
import {sorter} from "@/mixins/sorter";

export default {
  name: 'Cards',
  components: {
    TotalEvaluateEntity,
    CardsComponent,
    Paginator,
    PageHeading,
    CurrentStageColumnTemplate,
    TotalEvaluateSelectColumn,
    RouterLinkToCardColumnTemplate,
  },
  mixins: [paginator, sorter],
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    this.getEvaluations();
  },
  data() {
    return {
      loading: false,
      cards: [],
      evaluationTypes: this.$companyConfiguration.settings.getEnum('evaluation_types'),
      totalEvaluations: this.$companyConfiguration.settings.getEnum('evaluation_total_evaluations'),
      evaluations: {},
    }
  },
  beforeMount() {
    this.paginator.perPage = this.$companyConfiguration.card.getCardsPageDefaultPerPage();
    this.loadCards();
  },
  mounted() {},
  methods: {
    loadCards() {
      this.loading = true;

      requestSender('get', 'human-resources-evaluation/search', {
        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: this.$companyConfiguration.card.getCardsPageSearchExpand().join(),
      })
        .then(({data, paginator, sorter}) => {
          this.cards = data.cards;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getEvaluations() {
      requestSender('get', 'evaluation/search', {})
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },
  }
}
</script>

<style scoped lang="scss"></style>